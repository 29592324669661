import { allPieceTypes } from "../../services/zodiacEngine/const";
import { PieceType } from "../../services/zodiacEngine/types";
import styles from "./KingSelectionModal.module.css";
import ZodiacEngineService from "../ZodiacEngineService";

export interface KingSelectionModalProps {
  isOpen: boolean;
  isLight: boolean;
}

export default function KingSelectionModal(props: KingSelectionModalProps) {
  function handleOnClick(piece: PieceType): void {
    ZodiacEngineService.zodiacEngine.setKing(piece, props.isLight);
  }

  // duplicated code!!!
  function getPieces(isLight: boolean) {
    const suffix = isLight ? "light" : "dark";
    return (
      <div className={styles.pieceIcons}>
        {allPieceTypes.map((piece) => (
          <div key={`${piece}-${suffix}`} className={styles.pieceIconContainer}>
            <img
              src={`/icons/${piece}-${suffix}.png`}
              alt={`${piece}-${suffix}`}
              className={styles.pieceIcon}
              onClick={(e) => handleOnClick(piece)}
            />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      {props.isOpen && (
        <div className={styles.modalOverlay}>
          <div
            className={styles.modalContainer}
            onClick={(e) => e.stopPropagation()}
          >
            <h2>Select your king!</h2>
            <p>You can choose any of them!</p>
            {getPieces(props.isLight)}
          </div>
        </div>
      )}
    </div>
  );
}
