import {
  ISquare,
  GameState,
  Position,
  IPiece,
  PieceType,
  Mode,
  State,
} from "../types";
import { Square } from "../Square";
import { Piece } from "../Piece";

export class GameStateManager {
  private gameState: GameState;

  constructor() {
    this.gameState = {
      mode: Mode.Dev,
      state: State.PieceSelection,
      board: this.initializeBoard(),
      isLightTurn: true,
      turnCount: 0,
    };
  }

  initializeBoard(): ISquare[][] {
    const rows = 8;
    const columns = 5;
    const board: ISquare[][] = [];

    for (let row = 0; row < rows; row++) {
      const reversedRow = rows - 1 - row;
      board[reversedRow] = new Array(columns)
        .fill(null)
        .map(() => this.getEmptySquare());
    }

    return board;
  }

  resetGame() {
    this.gameState = {
      mode: Mode.Dev,
      state: State.PieceSelection,
      board: this.initializeBoard(),
      isLightTurn: true,
      turnCount: 0,
    };
  }

  getAllPieces(): IPiece[] {
    return this.gameState.board.reduce((acc, row) => {
      const rowPieces = row.reduce((rowAcc, square) => {
        return rowAcc.concat(square.Pieces);
      }, [] as IPiece[]);

      return acc.concat(rowPieces);
    }, [] as IPiece[]);
  }

  resetTurnCount() {
    this.gameState.turnCount = 0;
  }

  getBoardState(): ISquare[][] {
    return this.gameState.board;
  }

  getGameState(): GameState {
    return this.gameState;
  }

  setGameState(gameState: GameState): void {
    // this seems dangerous
    this.gameState = gameState;
  }

  setGameStateState(state: State): void {
    this.gameState.state = state;
  }

  setMode(mode: Mode) {
    this.gameState.mode = mode;
  }

  nextGameState() {
    switch (this.gameState.state) {
      case State.Searching:
        this.gameState.state = State.KingSelection;
        break;
      case State.KingSelection:
        this.gameState.state = State.PieceSelection;
        break;
      case State.PieceSelection:
        this.gameState.state = State.Playing;
        break;
      case State.Playing:
        this.gameState.state = State.PostGame;
        break;
      default:
        break;
    }
  }

  setLightPlayer(playerId: string) {
    this.gameState.lightPlayer = playerId;
  }

  setDarkPlayer(playerId: string) {
    this.gameState.darkPlayer = playerId;
  }

  changeTurns() {
    this.gameState.isLightTurn = !this.gameState.isLightTurn;
    if (this.gameState.state === State.Playing) {
      this.gameState.turnCount++;
    }
  }

  getPiecesAt(position: Position): IPiece[] {
    return this.gameState.board[position.row][position.col].Pieces;
  }

  getEmptySquare(): ISquare {
    return new Square([]);
  }

  emptyBoard(): void {
    this.gameState.board.forEach((row) => {
      row.forEach((square) => {
        square.Pieces = [];
      });
    });
  }

  placePiece(piece: IPiece, position: Position): void {
    this.gameState.board[position.row][position.col].Pieces.push(piece);
  }

  removePiece(position: Position, piece: IPiece): void {
    const square = this.getSquare(position);
    square.Pieces = square.Pieces.filter((x) => x !== piece);
  }

  addPiece(position: Position, piece: IPiece): void {
    const square = this.getSquare(position);
    square.Pieces.push(piece);
  }

  getSquare(position: Position): ISquare {
    const { row, col } = position;
    if (
      row < 0 ||
      row >= this.gameState.board.length ||
      col < 0 ||
      col >= this.gameState.board[0].length
    ) {
      throw new Error("Position is out of bounds");
    }
    return this.gameState.board[row][col];
  }

  findPiecePosition(piece: IPiece): Position | null {
    for (let row = 0; row < this.gameState.board.length; row++) {
      for (let col = 0; col < this.gameState.board[row].length; col++) {
        const square = this.gameState.board[row][col];
        if (square.Pieces.includes(piece)) {
          return { row, col };
        }
      }
    }
    return null; // Piece not found on the board
  }

  setStartingPiece(pieceType: PieceType, isLight: boolean, col: number): void {
    // validate
    if (this.gameState.state !== State.PieceSelection)
      throw Error("The game state is incorrect to set a starting piece");

    if (col < 0 || col > 4) throw new Error("invalid column");

    const row = isLight ? 0 : 7;
    const square = this.getSquare({ row, col });

    if (square.Pieces[0]) {
      throw new Error(
        `There is already a ${square.Pieces[0].type} in this column`
      );
    }

    var placedPieces = this.getPiecesInRow(row).map((x) => x.type);

    if (placedPieces.includes(pieceType))
      throw new Error(`A ${pieceType} has already been placed`);

    var isPlacingLastPiece = placedPieces.length === 4;
    var king = isLight ? this.gameState.lightKing! : this.gameState.darkKing!;
    var kingHasBeenPlaced = placedPieces.includes(king);

    if (isPlacingLastPiece && !kingHasBeenPlaced && pieceType !== king)
      throw new Error(`King needs to be placed, please place your ${king}`);

    // do work
    this.placePiece(new Piece(pieceType, isLight), { row, col });
    this.changeTurns();

    // check if we should move to playing
    var allLightPiecesPlaced = this.getPiecesInRow(0).length === 5;
    var allDarkPiecesPlaced = this.getPiecesInRow(7).length === 5;
    if (allLightPiecesPlaced && allDarkPiecesPlaced) {
      this.nextGameState();
    }
  }

  getPiecesInRow(row: number): IPiece[] {
    return Array.from(
      { length: 5 },
      (_, col) => this.getSquare({ row, col })?.Pieces[0] ?? []
    ).flat();
  }

  // method works okay in basic cases, but will fail in more complex cases, eg zombified piece
  checkGameEnd(): boolean {
    var pieces = this.getAllPieces();

    var isLightKingCaptured = !pieces.some(
      (x) => x.isLight && x.type === this.gameState.lightKing
    );
    var isDarkKingCaptured = !pieces.some(
      (x) => !x.isLight && x.type === this.gameState.darkKing
    );

    var hasLightKingWonRace = this.getPiecesInRow(7).some(
      (x) => x.isLight && x.type === this.gameState.lightKing
    );
    var hasDarkKingWonRace = this.getPiecesInRow(0).some(
      (x) => !x.isLight && x.type === this.gameState.darkKing
    );

    if (isDarkKingCaptured || hasLightKingWonRace) {
      this.gameState.winner = "Light";
      return true;
    }

    if (isLightKingCaptured || hasDarkKingWonRace) {
      this.gameState.winner = "Dark";
      return true;
    }
    return false;
  }
}
