import { Routes, Route } from "react-router-dom";
import globalStyles from "./App.module.css";
import BoardContainer from "./components/BoardContainer";
import { AuthProvider } from "./components/Providers/AuthContext";
import { GameContextProvider } from "./components/Providers/GameContext";
import HowToPlay from "./components/HowToPlay/HowToPlay";

function App() {
  return (
    <div className={globalStyles.app}>
      <AuthProvider>
        <GameContextProvider>
          <Routes>
            <Route path="/how-to-play" element={<HowToPlay />} />
            <Route path="/:navGameId?" element={<BoardContainer />} />
          </Routes>
        </GameContextProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
