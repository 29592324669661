import React, { useContext, useEffect, useState } from "react";
import styles from "./BoardContainer.module.css";
import Board from "./Board";
import {
  Position,
  Mode,
  State,
  PieceType,
} from "../services/zodiacEngine/types";
import { Piece } from "../services/zodiacEngine/Piece";
import PieceDrag from "./PieceDrag";
import { GameContext } from "./Providers/GameContext";
import ZodiacEngineService from "./ZodiacEngineService";
import { AuthContext } from "./Providers/AuthContext";
import KingSelectionModal from "./KingSelection/KingSelectionModal";
import { useLocation, useParams } from "react-router-dom";
import TurnPopover from "./TurnPopover/TurnPopover";

function BoardContainer() {
  const { navGameId } = useParams();
  const location = useLocation();

  useEffect(() => {
    if (navGameId) {
      checkUser();
      ZodiacEngineService.zodiacEngine.joinGame(navGameId, user!.uid);
      setGameId(navGameId);
    }
  }, [navGameId]);

  const { gameState, potentialMoves, isLightPlayer, isThisPlayersTurn } =
    useContext(GameContext)!;
  const { user } = useContext(AuthContext);

  const [gameId, setGameId] = useState(
    ZodiacEngineService.zodiacEngine.getGameId()
  );
  const [joinId, setJoinId] = useState("");
  const [copied, setCopied] = useState(false);

  const handleJoinIdChange = (event: any) => {
    setJoinId(event.target.value);
  };

  function checkUser() {
    if (!user) {
      throw new Error("User has no id for some strange reason");
    }
  }

  async function liveMode() {
    checkUser();
    var gameId = await ZodiacEngineService.zodiacEngine.switchToLiveMove(
      user!.uid
    );
    setGameId(gameId);
  }

  function joinGame() {
    checkUser();
    ZodiacEngineService.zodiacEngine.joinGame(joinId, user!.uid);
  }

  function devMode() {
    ZodiacEngineService.zodiacEngine.switchToDevMove();
  }

  function handleDrop(
    event: React.DragEvent<HTMLDivElement>,
    position: Position
  ) {
    event.preventDefault();
    const pieceData = event.dataTransfer.getData("piece");

    if (!pieceData) return;
    const { type, isLight } = JSON.parse(pieceData);

    // In dev mode we bypass a bunch of controls
    if (gameState.mode === Mode.Dev) {
      ZodiacEngineService.zodiacEngine.placePiece(
        new Piece(type as PieceType, isLight),
        position
      );
      return;
    }

    var isDroppedInStartingRow =
      (isLightPlayer() && position.row === 0) ||
      (!isLightPlayer() && position.row === 7);

    if (
      isThisPlayersTurn() &&
      isDroppedInStartingRow &&
      gameState.mode === Mode.Live &&
      gameState.state === State.PieceSelection
    ) {
      ZodiacEngineService.zodiacEngine.setStartingPiece(
        type as PieceType,
        isLightPlayer(),
        position.col
      );
    }
  }

  function handleDragOver(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
  }

  function getPieceSelectionControls(): JSX.Element {
    return (
      <div className={styles.pieceSelection}>
        <PieceDrag
          isDevMode={gameState.mode === Mode.Dev}
          isLight={isLightPlayer()}
        />
      </div>
    );
  }

  const copyGameIdToClipboard = () => {
    if (gameId) copyToClipboard(gameId);
  };

  const copyLinkToClipboard = async () => {
    if (!gameId) return;
    var path = window.location.origin + location.pathname + gameId;
    console.log(path);
    copyToClipboard(path);
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text!);
      setCopied(true);
      // Optionally reset 'copied' after a delay so the user can copy again
      setTimeout(() => setCopied(false), 1500);
    } catch (err) {
      alert("Failed to copy!");
      console.error("Failed to copy!", err);
    }
  };

  function isPieceSelectionOpen(): boolean {
    return gameState.state === State.KingSelection;
  }

  return (
    <div className={styles.container}>
      <TurnPopover
        isThisPlayersTurn={gameState.isLightTurn === isLightPlayer()}
        state={gameState.state}
        mode={gameState.mode}
        duration={2000}
      />
      <KingSelectionModal
        isOpen={isPieceSelectionOpen()}
        isLight={isLightPlayer()}
      />
      <div className={styles.controls}>
        <h1>Zodiac</h1>
        <button onClick={liveMode} disabled={gameState.mode === Mode.Live}>
          Create Game
        </button>
        <button onClick={copyLinkToClipboard}>
          {copied ? "Copied!" : "Copy link"}
        </button>

        <p />
        <button onClick={joinGame}>Join Game</button>
        <input type="text" value={joinId} onChange={handleJoinIdChange} />
        <p />
        <button onClick={devMode}>Return to Dev Mode</button>
        <p />
        {gameState.state === State.PieceSelection
          ? getPieceSelectionControls()
          : null}
      </div>
      <div>
        <Board
          gameState={gameState}
          uiState={{
            potentialMoves: potentialMoves,
          }}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        />
      </div>
      <div className={styles.debug}>
        <h1>Debug Console</h1>
        UserId: {user?.uid.slice(0, 8) + "..."} <br />
        Mode: {gameState.mode} <br />
        Game Id: {gameId ? gameId!.slice(0, 8) + "..." : "No Game Id"}
        <br />
        <button onClick={copyGameIdToClipboard}>
          {copied ? "Copied!" : "Copy Game Id"}
        </button>
        <br />
        State: {gameState.state} <br />
        Whose Turn: {gameState.isLightTurn ? "Light" : "Dark"} <br />
        This player: {isLightPlayer() ? "Light" : "Dark"} <br />
        Turn count: {gameState.turnCount} <br />
        Light King:
        {gameState.mode === Mode.Dev
          ? gameState.lightKing
          : gameState.lightKing
          ? "Set"
          : ""}
        <br />
        Dark King:
        {gameState.mode === Mode.Dev
          ? gameState.darkKing
          : gameState.darkKing
          ? "Set"
          : ""}
        <br />
      </div>
    </div>
  );
}

export default BoardContainer;
