import styles from "./BoardContainer.module.css";
import { PieceType } from "../services/zodiacEngine/types";
import { allPieceTypes } from "../services/zodiacEngine/const";

export interface PieceDragProps {
  isDevMode: boolean;
  isLight: boolean;
}

function PieceDrag(props: PieceDragProps) {
  function handleDragStart(
    event: React.DragEvent<HTMLImageElement>,
    piece: string,
    isLight: boolean
  ) {
    const pieceType = mapStringToPieceType(piece);
    event.dataTransfer.setData(
      "piece",
      JSON.stringify({ type: pieceType, isLight })
    );
  }

  function mapStringToPieceType(pieceString: string): PieceType {
    const lowerCasePiece = pieceString.toLowerCase();
    const pieceType = allPieceTypes.find(
      (type) => type.toLowerCase() === lowerCasePiece
    );
    if (pieceType) {
      return pieceType;
    } else {
      throw new Error(`Unknown piece type: ${pieceString}`);
    }
  }

  function getAllPieces() {
    return (
      <>
        {getPieces(true)}
        {getPieces(false)}
      </>
    );
  }

  function getPieces(isLight: boolean) {
    var suffix = isLight ? "light" : "dark";
    return (
      <div className={styles.pieceIcons}>
        {allPieceTypes.map((piece) => (
          <div key={`${piece}-${suffix}`} className={styles.pieceIconContainer}>
            <img
              src={`/icons/${piece}-${suffix}.png`}
              alt={`${piece}-${suffix}`}
              className={styles.pieceIcon}
              draggable="true"
              onDragStart={(e) => handleDragStart(e, piece, isLight)}
            />
          </div>
        ))}
      </div>
    );
  }
  return props.isDevMode ? getAllPieces() : getPieces(props.isLight);
}

export default PieceDrag;
