import { Piece } from "../zodiacEngine/Piece";
import {
  Direction,
  GameState,
  IPiece,
  ISquare,
  Mode,
  PieceType,
  State,
} from "../zodiacEngine/types";

// Simplified types for Firebase
interface FirebaseGameState {
  mode: string;
  board: FirebaseSquare[];
  state: string;
  lightKing?: string;
  darkKing?: string;
  lightPlayer?: string;
  darkPlayer?: string;
  isLightTurn: boolean;
  turnCount: number;
  winner: string;
}

interface FirebaseSquare {
  row: number;
  col: number;
  pieces: FirebasePiece[];
}

interface FirebasePiece {
  type: string;
  isLight: boolean;
  isKing: boolean;
  isPoisoned: boolean;
  isZombified: boolean;
  isFainted: boolean;
  isEntangled: boolean;
  isShadowed: boolean;
  direction: string | null;
  hasCharged: boolean;
  hasShed: boolean;
  hasLilyJumped: boolean;
  isMeadowed: boolean;
  isRiding: boolean;
  isHousing: boolean;
  isLone: boolean;
  isDormant: boolean;
}

// Conversion functions
export function convertToFirebaseState(
  gameState: GameState
): FirebaseGameState {
  return {
    mode: gameState.mode,
    state: gameState.state,
    board: gameState.board.flatMap((row, rowIndex) =>
      row.map((square, colIndex) => ({
        row: rowIndex,
        col: colIndex,
        pieces: square.Pieces.map(convertToFirebasePiece),
      }))
    ),
    lightKing: gameState.lightKing ?? "",
    darkKing: gameState.darkKing ?? "",
    lightPlayer: gameState.lightPlayer ?? "",
    darkPlayer: gameState.darkPlayer ?? "",
    isLightTurn: gameState.isLightTurn,
    turnCount: gameState.turnCount,
    winner: gameState.winner ?? "",
  };
}

export function convertToFirebasePiece(piece: IPiece): FirebasePiece {
  return {
    type: piece.type,
    isLight: piece.isLight,
    isKing: piece.isKing,
    isPoisoned: piece.isPoisoned,
    isZombified: piece.isZombified,
    isFainted: piece.isFainted,
    isEntangled: piece.isEntangled,
    isShadowed: piece.isShadowed,
    direction: piece.direction,
    hasCharged: piece.hasCharged,
    hasShed: piece.hasShed,
    hasLilyJumped: piece.hasLilyJumped,
    isMeadowed: piece.isMeadowed,
    isRiding: piece.isRiding,
    isHousing: piece.isHousing,
    isLone: piece.isLone,
    isDormant: piece.isDormant,
  };
}

export function convertFromFirebaseState(
  firebaseState: FirebaseGameState
): GameState {
  const board: ISquare[][] = Array(8)
    .fill(null)
    .map(() =>
      Array(5)
        .fill(null)
        .map(() => ({ Pieces: [] }))
    );

  firebaseState.board.forEach((square) => {
    board[square.row][square.col] = {
      Pieces: square.pieces.map(convertFromFirebasePiece),
    };
  });

  return {
    mode: firebaseState.mode as Mode,
    state: firebaseState.state as State,
    board: board,
    lightKing: (firebaseState.lightKing as PieceType) || undefined,
    darkKing: (firebaseState.darkKing as PieceType) || undefined,
    lightPlayer: firebaseState.lightPlayer || undefined,
    darkPlayer: firebaseState.darkPlayer || undefined,
    isLightTurn: firebaseState.isLightTurn as boolean,
    turnCount: firebaseState.turnCount as number,
    winner: (firebaseState.winner as "Light" | "Dark") || undefined,
  };
}

export function convertFromFirebasePiece(firebasePiece: FirebasePiece): IPiece {
  const piece = new Piece(
    firebasePiece.type as PieceType,
    firebasePiece.isLight,
    firebasePiece.direction as Direction
  );

  // Set all the properties
  Object.assign(piece, firebasePiece);

  return piece;
}
