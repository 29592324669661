function HowToPlay() {
  return (
    <div>
      <h1>How to Play</h1>
      <p>Explain the rules here...</p>
      {/* You can add any other relevant info or styling */}
    </div>
  );
}

export default HowToPlay;
