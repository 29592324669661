import { GameStateManager } from "./Managers/GameStateManager";
import { MovementManager } from "./Managers/MovementManager";
import { EventManager } from "./Managers/EventManager";
import { GameState, State, PieceType, MoveType, SideEffect } from "./types";
import { Piece } from "./Piece";
import { IMove, IPiece, Position } from "./types";

export type GameStateListener = () => void;

export class ZodiacEngine {
  private eventManager: EventManager;
  private gameStateManager: GameStateManager;
  private movementManager: MovementManager;

  constructor() {
    this.gameStateManager = new GameStateManager();
    this.movementManager = new MovementManager(this.gameStateManager);
    this.eventManager = new EventManager(this.gameStateManager);
  }

  // Game state and game modes
  getGameState(): GameState {
    return { ...this.gameStateManager.getGameState() };
  }

  async switchToLiveMove(playerId: string): Promise<string> {
    return await this.eventManager.setupLiveMode(playerId);
  }

  switchToDevMove() {
    this.eventManager.setupDevMode();
  }

  // Event Handling
  subscribeToGameStateChanges(listener: GameStateListener): void {
    this.eventManager.subscribeToGameStateChanges(listener);
  }

  unsubscribeToGameStateChanges(listener: GameStateListener): void {
    this.eventManager.unsubscribeToGameStateChanges(listener);
  }

  ungracefulTermination() {
    if (this.eventManager.getGameId()) {
      this.gameStateManager.getGameState().state = State.Terminated;
      console.log(this.eventManager.getGameId());
      console.log("Ungraceful termination");
    }
  }

  getGameId(): string | undefined {
    return this.eventManager.getGameId();
  }

  joinGame(gameId: string, playerId: string) {
    this.eventManager.joinGame(gameId, playerId);
  }

  // Piece getting
  placePiece(piece: Piece, position: Position) {
    this.gameStateManager.placePiece(piece, position);
    this.eventManager.notifyLocalListeners();
  }

  getPiecesAt(treePosition: { row: number; col: number }): IPiece[] {
    return this.gameStateManager.getPiecesAt(treePosition);
  }

  setKing(pieceType: PieceType, isLight: boolean) {
    var gameState = this.gameStateManager.getGameState();
    var text = isLight ? "light" : "dark";
    if (gameState.state !== State.KingSelection)
      throw Error(`The game state is incorrect to set a ${text} king`);

    if ((isLight && gameState.lightKing) || (!isLight && gameState.darkKing))
      throw Error(`The ${text} king is already set`);

    if (isLight) gameState.lightKing = pieceType;
    else gameState.darkKing = pieceType;

    if (gameState.lightKing && gameState.darkKing)
      this.gameStateManager.nextGameState();

    this.eventManager.notifyLocalListeners();
    this.eventManager.sendUpdateToRemote();
  }

  setStartingPiece(pieceType: PieceType, isLight: boolean, col: number) {
    this.gameStateManager.setStartingPiece(pieceType, isLight, col);
    this.eventManager.sendUpdateToRemote();
    this.eventManager.notifyLocalListeners();
  }

  // Movement Handling
  getValidMoves(position: Position, piece: IPiece): IMove[] {
    return this.movementManager.getValidMoves(position, piece);
  }

  makeMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece,
    toPiece: IPiece | null,
    moveType: MoveType,
    sideEffects: SideEffect[]
  ): boolean {
    const moveMade = this.movementManager.makeMove(
      fromPosition,
      toPosition,
      piece,
      toPiece,
      moveType,
      sideEffects
    );
    if (moveMade) {
      // Notify listeners and send updates if necessary
      this.eventManager.notifyLocalListeners();
      this.eventManager.sendUpdateToRemote();

      this.runPostTurn();

      this.checkGameEnd();
    }
    return moveMade;
  }

  checkGameEnd() {
    var isGameOver = this.gameStateManager.checkGameEnd();
    if (isGameOver) {
      console.log(
        "Game Over! Winner: " + this.gameStateManager.getGameState().winner
      );
      this.gameStateManager.nextGameState();
      this.eventManager.notifyLocalListeners();
      this.eventManager.sendUpdateToRemote();
    } else {
      this.changeTurns();
    }
  }

  runPostTurn() {
    var pieces = this.gameStateManager.getAllPieces();
    pieces.forEach((piece) => {
      // piece.effects.forEach((effect) => {
      //   // if (effect instanceof ITurnCountEffect) {
      //   // }
      // });
    });
    // here is where we can run post turn effects eg
    // disabling a poisoned state
    //
  }

  changeTurns() {
    this.gameStateManager.changeTurns();
    this.eventManager.notifyLocalListeners();
    this.eventManager.sendUpdateToRemote();
  }
}
